import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IProjectsStore } from './projects.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'projects' })
export class ProjectsStore extends Store<IProjectsStore> {
  public constructor() {
    super({});
  }
}
