import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IPublicOpportunityState } from './public-opportunities.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'public-opportunities' })
export class PublicOpportunitiesStore extends Store<IPublicOpportunityState> {
  public constructor() {
    super({});
  }
}
