import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseApi } from 'sustainment-component';
import { IPublicOpportunityModel } from '../store/public-opportunities/public-opportunities.model';

@Injectable()
export class PublicOpportunityApi extends BaseApi {
  protected urlBase: string;
  private _relativeUrl = 'public-opportunities';
  public constructor(httpClient: HttpClient) {
    super(httpClient);
    this.urlBase = environment.projectsApiBaseUrl;
  }

  public getPublicOpportunities(): Observable<IPublicOpportunityModel[]> {
    return this.get<IPublicOpportunityModel[]>(this._relativeUrl);
  }

  public sendRequest(opportunityId: string, note: string): Observable<void> {
    return this.post<void>(this._relativeUrl + `/${opportunityId}/request`, {
      note,
    });
  }

  public withdrawRequest(opportunityId: string): Observable<void> {
    return this.delete(this._relativeUrl + `/${opportunityId}/request`);
  }

  public checkRequestStatus(
    opportunityId: string,
    requestId: number
  ): Observable<boolean> {
    return this.get<boolean>(
      this._relativeUrl + `/${opportunityId}/request/${requestId}/status`
    );
  }

  public acceptRequest(
    opportunityId: string,
    requestId: number,
    note: string
  ): Observable<void> {
    return this.post<void>(
      this._relativeUrl + `/${opportunityId}/request/${requestId}/accept`,
      { note }
    );
  }

  public declineRequest(
    opportunityId: string,
    requestId: number,
    note: string
  ): Observable<void> {
    return this.post<void>(
      this._relativeUrl + `/${opportunityId}/request/${requestId}/reject`,
      { note }
    );
  }

  public getProjectPendingRequestCount(): Observable<{ total: number }> {
    return this.get(this._relativeUrl + '/pending-count');
  }

  public addUserToOpportunityTeam(opportunityId: string, userId: string): Observable<void> {
    return this.post(this._relativeUrl + '/team', { opportunityId, userId })
  }

  public removeUserFromOpportunityTeam(opportunityId: string, userId: string): Observable<void> {
    return this.delete(this._relativeUrl + `/team/${opportunityId}/remove/${userId}`)
  }

  public joinOpportunityTeam(opportunityId: string): Observable<void> {
    return this.post(this._relativeUrl + '/team/join', { opportunityId })
  }

  public getOpportunityTeam(opportunityId: string): Observable<OpportunityTeamMemberDto[]> {
    return this.get(this._relativeUrl + `/team/${opportunityId}`)
  }
}

export interface OpportunityTeamMemberDto {
  userId: string;
  isOwner: boolean;
}