import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { IProjectsStore } from './projects.model';
import { ProjectsStore } from './projects.store';

@Injectable({ providedIn: 'root' })
export class ProjectsQuery extends Query<IProjectsStore> {
  public projects$ = this.select((x) => x.projects);
  public otherProjects$ = this.select((x) => x.otherProjects);
  public pendingCount$ = this.select((x) => x.pendingCount);
  public spaceUsed$ = this.select((x) => x.spaceUsed);

  public projectsUnread$ = this.select((x) => x.projects || []).pipe(
    map(
      (projects) =>
        projects
          .map((x) => x.notifications)
          .filter(
            (notifications) =>
              notifications &&
              (
                Object.keys(notifications) as Array<keyof typeof notifications>
              ).some((key) => !!notifications[key])
          ).length
    )
  );

  public constructor(protected store: ProjectsStore) {
    super(store);
  }
}
