import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { PublicOpportunityApi } from 'src/app/api/public-opportunity.api';
import { PublicOpportunitiesStore } from './public-opportunities.store';
import { NotifyService } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
export class PublicOpportunitiesAction {
  public constructor(
    private store: PublicOpportunitiesStore,
    private _api: PublicOpportunityApi,
    private notifyService: NotifyService,

  ) {}

  public getPublicOpportunitiesData(forceUpdate?: boolean): void {
    if (!this.store.getValue()?.data?.length || forceUpdate) {
      this.store.setLoading(true);
      this._api.getPublicOpportunities().subscribe(
        (res) => {
          this.store.update({
            data: res,
          });
          this.store.setLoading(false);
        },
        () => this.store.setLoading(false)
      );
    }
  }

  public sendRequest(opportunityId: string, note: string): void {
    this.store.setLoading(true);

    this._api
      .sendRequest(opportunityId, note)
      .pipe(mergeMap(() => this._api.getPublicOpportunities()))
      .subscribe(
        (res) => {
          this.store.update({
            data: res,
          });
          this.store.setLoading(false);
        },
        () => this.store.setLoading(false)
      );
  }

  public withdrawRequest(opportunityId: string): void {
    this.store.setLoading(true);

    this._api
      .withdrawRequest(opportunityId)
      .pipe(mergeMap(() => this._api.getPublicOpportunities()))
      .subscribe(
        (res) => {
          this.store.update({
            data: res,
          });
          this.store.setLoading(false);
        },
        () => this.store.setLoading(false)
      );
  }

  public acceptRequest(
    opportunityId: string,
    requestId: number,
    note: string
  ): Observable<void> {
    this.store.setLoading(true);
    return this._api.acceptRequest(opportunityId, requestId, note).pipe(
      tap(() => {
        this.notifyService.showSuccess(
          'Request accepted successfully.'
        );
        this.getPendingRequestCount();
      })
    );
  }

  public declineRequest(
    opportunityId: string,
    requestId: number,
    note: string
  ): Observable<void> {
    this.store.setLoading(true);
    return this._api.declineRequest(opportunityId, requestId, note).pipe(
      tap(() => {
        this.notifyService.showSuccess(
          'Request declined successfully.'
        );
        this.getPendingRequestCount();
      })
    );
  }

  public getPendingRequestCount(): void {
    this.store.setLoading(true);
    this._api.getProjectPendingRequestCount().subscribe((x) => {
      this.store.update({
        pendingCount: x.total,
      });
      this.store.setLoading(false);
    });
  }
}
